const vvipDomain = process.env.REACT_APP_VVIP_DOMAIN || 'uat.virtualvip.club'
const backofficeUrl = `https://${vvipDomain}`
const promotionActive = false
export const arrSepa = ["FI","AT","PT","BE","BG","ES","HR","CY","CZ","DK","EE","FR","DE","GI","GR","GB","HU","IS","IE","IT","LV","LU","LI","LT","MT","MC","NL","NO","PL","RO","SM","SK","SI","SE","CH"]
export const environment = process.env.REACT_APP_AWS_ACCOUNT || 'none'
export const currency = 'EUR'
export const couponExpiration = 'YEAR'
export const freeTrialDays = '15'
export const maxPointsMonth = '16000'
export const maxPointsYear = '96000'
export const stripeTestPlan = 'price_1LhHDzAI0ZCvTanHeE9BwRcZ'
export const bottomGoldAvailable = false
export const g_adminsCouponsAv = true
export const rewardsAvailable = true
export const invoiceAvailable = true
export const paymentAdminsAvailable = false
export const testPaymentActive = false
export const upgradeAvailable = false
export const goldAvailable = true
export const paypalAvailable = false
export const deleteAvailable = true
export const usersFirst = '5'
export const usersSecond = '10'
export const usersThird = '25'
export const usersFourth = '50'
export const usersFifth = '100'
export const usersSixth = '250'
export const usersSeventh = '500'
export const usersEighth = '1000000000'
export const chargeFirstYear = promotionActive ? '79.70' : '574.50'
export const chargeSecondYear = promotionActive ? '137.70' : '1134.90'
export const chargeThirdYear = promotionActive ? '233.70' : '1926.20'
export const chargeFourthYear = promotionActive ? '469.70' : '3871.30'
export const chargeFifthYear = promotionActive ? '777.70' : '6509.80'
export const chargeSixthYear = promotionActive ? '1533.70' : '12992.40'
export const chargeSeventhYear = promotionActive ? '2597.70' : '21410.20'
export const chargeEighthYear = promotionActive ? '4997.70' : '41191.00'
export const chargeFirstMonth = promotionActive ? '9.70' : '69.90'
export const chargeSecondMonth = promotionActive ? '17.70' : '145.90'
export const chargeThirdMonth = promotionActive ? '33.70' : '277.80'
export const chargeFourthMonth = promotionActive ? '69.70' : '574.50'
export const chargeFifthMonth = promotionActive ? '117.70' : '970.10'
export const chargeSixthMonth = promotionActive ? '233.70' : '1926.20'
export const chargeSeventhMonth = promotionActive ? '397.70' : '3277.80'
export const chargeEighthMonth = promotionActive ? '777.70' : '6409.80'
export const chargeUserMonth = promotionActive ? '9.70' : '69.90'
export const chargeUserYear = promotionActive ? '79.70' : '574.50'
export const vvipUrl = `https://${vvipDomain}`
export const digitalGoldUrl = 'https://test-atlanticoin.adamantic.cloud'
export const goldenBadgeSrc = `${vvipUrl}/assets/img/golden_coin_token.png`
export const newGoldenBadgeSrc = `${vvipUrl}/assets/img/atlantic_coin_token.png`
export const newGoldenBadgeLargeSrc = `${vvipUrl}/assets/img/atlantic_coin_large.png`
export const adminEmail = 'support@virtualvip.club'
export const logoLarge = `${vvipUrl}/assets/img/logo_large.jpg`
export const adminImage = `${vvipUrl}/assets/img/admin_image.png`
export const userImage = `${vvipUrl}/assets/img/user_image.png`
export const stripeBadgeSrc = `${vvipUrl}/assets/img/badge_stripe.svg`
export const stripeLogoSrc = `${vvipUrl}/assets/img/logo_stripe.png`
export const paypalBadgeSrc = `${vvipUrl}/assets/img/badge_paypal.png`
export const paypalLogoSrc = `${vvipUrl}/assets/img/logo_paypal.png`
export const apiEndpoint = `https://${vvipDomain}/api`
export const stripePublicKey = 'pk_test_51Lg64BAI0ZCvTanHjH1H47xasqLrIKW2m9YWky01HBvxquCEr0CHVQesRIEkIDCpYpXjiZ5A5YpBWeV9jvLALJPc00DuAVqPBN'
export const nameplanyUA = 'VirtualVIP app usage: Annual (1 user)'
export const nameplanmUA = 'VirtualVIP app usage: Monthly (1 user)'
export const nameplanyH = 'VirtualVIP app usage: Annual (Unlimited users)'
export const nameplanyG = 'VirtualVIP app usage: Annual (1-500 users)'
export const nameplanyF = 'VirtualVIP app usage: Annual (1-250 users)'
export const nameplanyE = 'VirtualVIP app usage: Annual (1-100 users)'
export const nameplanyD = 'VirtualVIP app usage: Annual (1-50 users)'
export const nameplanyC = 'VirtualVIP app usage: Annual (1-25 users)'
export const nameplanyB = 'VirtualVIP app usage: Annual (1-10 users)'
export const nameplanyA = 'VirtualVIP app usage: Annual (1-5 users)'
export const nameplanmH = 'VirtualVIP app usage: Monthly (Unlimited users)'
export const nameplanmG = 'VirtualVIP app usage: Monthly (1-500 users)'
export const nameplanmF = 'VirtualVIP app usage: Monthly (1-250 users)'
export const nameplanmE = 'VirtualVIP app usage: Monthly (1-100 users)'
export const nameplanmD = 'VirtualVIP app usage: Monthly (1-50 users)'
export const nameplanmC = 'VirtualVIP app usage: Monthly (1-25 users)'
export const nameplanmB = 'VirtualVIP app usage: Monthly (1-10 users)'
export const nameplanmA = 'VirtualVIP app usage: Monthly (1-5 users)'
export const stripeplanmCA = promotionActive ? 'price_1M4QngAI0ZCvTanHLJsTcOwm' : 'price_1N4KO6AI0ZCvTanH7EKEdzoi'
export const stripeplanmCB = promotionActive ? 'price_1M4R9oAI0ZCvTanHgeOSOunr' : 'price_1MiavCAI0ZCvTanH1uOYPQ0e'
export const stripeplanmCC = promotionActive ? 'price_1M4RBbAI0ZCvTanH6RiQnIJE' : 'price_1MiawIAI0ZCvTanHz5XLNA6A'
export const stripeplanmCD = promotionActive ? 'price_1M4RCzAI0ZCvTanHHEtLxt4I' : 'price_1Mib3wAI0ZCvTanHaBzy8NMX'
export const stripeplanmCE = promotionActive ? 'price_1M4REHAI0ZCvTanHmbRkjsV1' : 'price_1Mib4fAI0ZCvTanHtu8meKEe'
export const stripeplanmCF = promotionActive ? 'price_1M4RFDAI0ZCvTanHZ5vveFYf' : 'price_1Mib5bAI0ZCvTanHBuOW28vW'
export const stripeplanmCG = promotionActive ? 'price_1M4RGUAI0ZCvTanH1QFQKbBl' : 'price_1Mib6BAI0ZCvTanHE6bohEoK'
export const stripeplanmCH = promotionActive ? 'price_1M4RIuAI0ZCvTanHMTM7L4Ek' : 'price_1Mib7MAI0ZCvTanHll5fMSoq'
export const stripeplanyCA = promotionActive ? 'price_1N4KAuAI0ZCvTanHIVxYA7G9' : 'price_1Mib9FAI0ZCvTanHZiHSTZpK'
export const stripeplanyCB = promotionActive ? 'price_1M4S2SAI0ZCvTanHiL0xLF9q' : 'price_1Mib9sAI0ZCvTanH4o3HpMvi'
export const stripeplanyCC = promotionActive ? 'price_1M4S4RAI0ZCvTanHV4pISZVd' : 'price_1MibBIAI0ZCvTanHvvdSo19M'
export const stripeplanyCD = promotionActive ? 'price_1M4S9fAI0ZCvTanHiRyNsQ84' : 'price_1MibBlAI0ZCvTanHDTktCvMr'
export const stripeplanyCE = promotionActive ? 'price_1M4SCeAI0ZCvTanHvZUDUV6u' : 'price_1N4KQNAI0ZCvTanHXoPGsE8P'
export const stripeplanyCF = promotionActive ? 'price_1N4KDlAI0ZCvTanHX8D8y4vO' : 'price_1N4KVsAI0ZCvTanHlHNf7ofP'
export const stripeplanyCG = promotionActive ? 'price_1M4SInAI0ZCvTanH1b0IOSRS' : 'price_1MibEjAI0ZCvTanHrd1M3Y7U'
export const stripeplanyCH = promotionActive ? 'price_1M4SKrAI0ZCvTanHVVuoGGPJ' : 'price_1MibGqAI0ZCvTanHwnb6rr9U'
export const stripeplanyUA = promotionActive ? 'price_1N4K4jAI0ZCvTanHc9RvaGfx' : 'price_1MiaqkAI0ZCvTanHRz0vNm4I'
export const stripeplanmUA = promotionActive ? 'price_1M4SQOAI0ZCvTanH0WU6p4qy' : 'price_1N4KLoAI0ZCvTanHOia9M69b'
export const paypalplanmCA = 'P-8TY199114G108530TMM6XOLQ'//DA CAMBIARE
export const paypalplanmCB = 'P-70K00155WK965191VMM6XQUQ'//DA CAMBIARE
export const paypalplanmCC = 'P-34D27120S6429153LMM6XRSI'//DA CAMBIARE
export const paypalplanmCD = 'P-70G11135Y2251333HMM6XS3I'//DA CAMBIARE
export const paypalplanmCE = 'P-8TY199114G108530TMM6XOLQ'//DA CAMBIARE
export const paypalplanmCF = 'P-70K00155WK965191VMM6XQUQ'//DA CAMBIARE
export const paypalplanmCG = 'P-34D27120S6429153LMM6XRSI'//DA CAMBIARE
export const paypalplanmCH = 'P-70G11135Y2251333HMM6XS3I'//DA CAMBIARE
export const paypalplanyCA = 'P-7JF04637DN958992UMM6X3GI'//DA CAMBIARE
export const paypalplanyCB = 'P-7EB259601U8367707MM6YE2A'//DA CAMBIARE
export const paypalplanyCC = 'P-7HM46762GX5175308MM6YD2A'//DA CAMBIARE
export const paypalplanyCD = 'P-8KV780839F668923AMM6YGTA'//DA CAMBIARE
export const paypalplanyCE = 'P-7JF04637DN958992UMM6X3GI'//DA CAMBIARE
export const paypalplanyCF = 'P-7EB259601U8367707MM6YE2A'//DA CAMBIARE
export const paypalplanyCG = 'P-7HM46762GX5175308MM6YD2A'//DA CAMBIARE
export const paypalplanyCH = 'P-8KV780839F668923AMM6YGTA'//DA CAMBIARE
export const paypalplanyUA = 'P-1SE32710RF771194RMM6YIIY'//DA CAMBIARE
export const paypalplanmUA = 'P-0WD99317HM440410BMM6XZFA'//DA CAMBIARE
export const paypalClientId = 'AavfXjUp76sdXOaWe9ouh8O8XyxHPqt9XrtaIU9qujE5MvCj9wZlo77IBpm8M6ISfa5iLFWmjHKzG2u2'

export const footerConfig = {
  Social: 'Connect with Us on:',
  Website: 'https://www.coach4charity.com/',//DA CAMBIARE
  Facebook: 'https://www.facebook.com/Coach4Charity',//DA CAMBIARE
  Linkedin: 'https://www.linkedin.com/company/coach-4-charity/',//DA CAMBIARE
  Instagram: 'https://www.instagram.com/coach4charity',//DA CAMBIARE
  Copyrights: 'VirtualVip 2022',
  AllRightsReserved: '| All rights reserved',
  Suggestions: ' | For suggestions and improvements',
  hrefFooter: `mailto:${adminEmail}`,
  imageUrl: `${vvipUrl}/assets/img/logo_small_compact.jpg`,

}

export const rewardsConfig = {
  first_level_first_prize: 40.00,
  first_level_second_prize: 20.00,
  first_level_third_prize: 10.00,
  second_level_first_prize: 50.00,
  second_level_second_prize: 25.00,
  second_level_third_prize: 12.50,
  third_level_first_prize: 60.00,
  third_level_second_prize: 30.00,
  third_level_third_prize: 15.00,
  fourth_level_first_prize: 70.00,
  fourth_level_second_prize: 35.00,
  fourth_level_third_prize: 17.50,
  fifth_level_first_prize: 80.00,
  fifth_level_second_prize: 40.00,
  fifth_level_third_prize: 20.00,
  sixth_level_first_prize: 90.00,
  sixth_level_second_prize: 45.00,
  sixth_level_third_prize: 22.50,
  seventh_level_first_prize: 100.00,
  seventh_level_second_prize: 50.00,
  seventh_level_third_prize: 25.00,
  eighth_level_first_prize: 110.00,
  eighth_level_second_prize: 55.00,
  eighth_level_third_prize: 27.50,
  max_session_first_prize: 4,
  max_session_second_prize: 10,
  max_session_third_prize: 1000000000
}

export const authConfig = {
  region: process.env.REACT_APP_REGION || 'eu-west-1',
  logoutUrl: backofficeUrl,
  poolId: process.env.REACT_APP_COGNITO_POOL_ID || 'eu-west-1_VTzxUbXYd',
  callbackUrl: `${backofficeUrl}/callback`,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '4ekkk4f3u9uae70midfdqse4k6'
}